import service from "@/utils/request";

//按照企业获取高端人才需求列表
export function queryTopTalentsByCmpy(params) {
  return service({
    url: "/policy/top-talents-nees/queryTopTalentsByCmpy",
    method: "get",
    params,
  });
}


//按照企业获取高端人才需求 批次列表
export function queryTopTalentsBatch(params) {
  return service({
    url: "/policy/top-talents-nees/queryTopTalentsBatch",
    method: "get",
    params,
  });
}
