<template>
  <div class="nomore-container">
    <div class="nomore">没有更多了</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "Nomore",
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.nomore-container {
  .nomore {
    text-align: center;
    margin: 0.3rem 0;
    color: #999;
  }
}
</style>
