<template>
  <div class="page-wrapper">
    <div class="header">
      <div class="header-title">河南郑州航空港人才需求目录</div>
      <div class="row2">
        <span class="cmpy-name">航空港区人才办</span>
        <go-wechat-public />
      </div>
      <van-tabs
        class="need-tabs"
        :ellipsis="false"
        v-model="queryForm.batchCode"
        @change="onSearch"
      >
        <van-tab
          v-for="item in batchList"
          :title="item.label"
          :key="item.value"
          :name="item.value"
        >
        </van-tab>
      </van-tabs>
    </div>

    <van-search
      class="need-search"
      v-model="queryForm.name"
      placeholder="请输入搜索关键词"
      show-action
      clearable
      @search="onSearch"
      @clear="onClear"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-list
      v-model="loading"
      :finished="noMore"
      finished-text="没有更多了"
      @load="handleScrollBottom"
    >
      <ul class="data-list">
        <template v-for="(item, index) in dataList">
          <li class="data-item" :key="index" v-if="isShowJob(item)">
            <div class="cmpyName">{{ item.cmpyName }}</div>
            <div
              class="job"
              v-for="(voItem, voIndex) in item.showMore
                ? item.voList
                : item.voListShow"
              :key="voIndex"
              @click="handlePopupShow(item, index, voItem, voIndex)"
            >
              <div class="row1">
                <div class="post-name">{{ voItem.postName }}</div>
                <div class="post-treatment">{{ voItem.postTreatment }}</div>
              </div>
              <div class="row2">
                <div class="tag">{{ voItem.education || "学历不限" }}</div>
                <div class="tag">招聘{{ voItem.recruitNum || 0 }}人</div>
              </div>
            </div>
            <div
              class="read-more"
              v-if="!item.showMore && item.voList.length > 2"
            >
              <p @click="readMore(item, index)">查看更多</p>
            </div>
            <div class="read-more" v-if="item.showMore">
              <p @click="readLess(item, index)">收起</p>
            </div>
          </li>
        </template>
      </ul>
      <van-empty v-if="noData" description="暂无数据" />
    </van-list>

    <van-popup
      v-model="popupShow"
      position="bottom"
      round
      :style="{ height: '80%' }"
    >
      <div class="detail-container">
        <ul class="item-list">
          <div class="item">
            <div class="item-label">行业门类</div>
            <div class="item-value">{{ detail.industry || "无" }}</div>
          </div>
          <div class="item">
            <div class="item-label">单位名称</div>
            <div class="item-value">{{ cmpyDetail.cmpyName || "无" }}</div>
          </div>
          <div class="item">
            <div class="item-label">单位地址</div>
            <div class="item-value">{{ detail.cmpyAddr || "无" }}</div>
          </div>
          <div class="item">
            <div class="item-label">岗位名称</div>
            <div class="item-value">{{ detail.postName || "无" }}</div>
          </div>
          <div class="item">
            <div class="item-label">岗位待遇</div>
            <div class="item-value">{{ detail.postTreatment || "无" }}</div>
          </div>
          <div class="item">
            <div class="item-label">招聘人数</div>
            <div class="item-value">{{ detail.recruitNum || "无" }}</div>
          </div>
          <div class="item">
            <div class="item-label">学历要求</div>
            <div class="item-value">{{ detail.education || "无" }}</div>
          </div>
          <div class="item">
            <div class="item-label">专业要求</div>
            <div class="item-value">{{ detail.major || "无" }}</div>
          </div>
          <div class="item">
            <div class="item-label">备注</div>
            <div class="item-value">{{ detail.remark || "无" }}</div>
          </div>
          <div class="item">
            <div class="item-label">联系方式</div>
            <div class="item-value">{{ detail.contact || "无" }}</div>
          </div>
        </ul>

        <div class="cancel" @click="cancel">取消</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import NoMore from "@/components/NoMore";
import {
  queryTopTalentsByCmpy,
  queryTopTalentsBatch,
} from "@/api/policy/topTalentsNees";
import GoWechatPublic from "@/components/GoWechatPublic";

export default {
  data() {
    return {
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        name: "",
        batchCode: "",
      },
      batchList: [
        {
          label: "全部",
          value: "",
        },
      ],

      loading: false, //搜索状态
      noMore: false,
      noData: false,
      dataList: [],
      navHeight: 46,
      popupShow: false,
      detail: {},
      cmpyDetail: {},
    };
  },
  components: {
    NoMore,
    GoWechatPublic,
  },
  watch: {},
  computed: {
    showNav() {
      return this.$store.state.global.showNav;
    },
  },
  mounted() {
    this.getBatch();
    this.getList();
  },

  methods: {
    //滚到底
    handleScrollBottom() {
      this.queryForm.pageNum++;
      this.getList();
    },
    // 兼容 volist
    isShowJob(item) {
      let bol = false;
      if (Object.prototype.hasOwnProperty.call(item, "voList")) {
        if (item.voList.length > 0) {
          bol = true;
        }
      }
      return bol;
    },
    getBatch() {
      queryTopTalentsBatch()
        .then((res) => {
          let data = res.data || [];
          let crtList = [];
          data.map((item) => {
            crtList.push({
              label: item.batchName,
              value: item.batchCode,
            });
          });
          this.batchList = this.batchList.concat(crtList);
        })
        .catch(() => {});
    },
    getList() {
      this.loading = true;
      const params = this.queryForm;
      queryTopTalentsByCmpy(params)
        .then((res) => {
          this.loading = false;
          if (!res) {
            this.noMore = true;
            this.noData = true;
            return;
          }
          if (this.queryForm.pageNum == 1) {
            this.dataList = res.data.records || [];
          } else {
            this.dataList = [...this.dataList, ...res.data.records];
          }
          this.dataList = this.dataList.map((item) => {
            return {
              ...item,
              voListShow: Object.prototype.hasOwnProperty.call(item, "voList")
                ? item.voList.slice(0, 2)
                : [],
              showMore: 0,
            };
          });
          //判断nomore
          if (res && res.data.pages <= res.data.current) {
            this.noMore = true;
          } else {
            this.noMore = false;
          }
          if (res.data.total == 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
        })
        .catch(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
    },
    handlePopupShow(item, index, voItem, voIndex) {
      this.popupShow = true;
      this.detail = voItem;
      this.cmpyDetail = item;
    },
    readMore(item, index) {
      this.dataList[index].showMore = 1;
    },
    readLess(item, index) {
      this.dataList[index].showMore = 0;
    },
    cancel() {
      this.popupShow = false;
    },
    onSearch() {
      this.queryForm.pageNum = 1;
      this.getList();
    },
    onClear() {
      this.queryForm.pageNum = 1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  position: relative;
  background: #f8f8f8;
  // height: 100vh;

  ::v-deep .need-search {
    margin-bottom: 0.2rem;
  }
}
.header {
  background: #fff;
  color: #303133;
  padding: 0.4rem 0.3rem 0.3rem 0.3rem;
  min-height: 3.5rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../../assets/needTalent/bg.jpg);
  .header-title {
    font-size: 0.5rem;
    line-height: 1.2;
    margin-bottom: 0.18rem;
  }
  .row2 {
    margin-bottom: 0.7rem;
    .cmpy-name {
      display: inline-block;
      margin-right: 0.2rem;
      font-size: 0.3rem;
      color: #909399;
    }
  }
  &::v-deep .go-public {
    color: #606266;
    font-size: 0.3rem;
  }
}

.need-tabs {
  &::v-deep {
    .van-tabs__nav {
      background-color: transparent;
    }
    .van-tab {
      color: #303133;
    }

    .van-tab--active {
      color: #3c37d2;
      scale: 1.4;
      font-weight: bold;
    }
  }
}

.scroll-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.loading {
  display: flex;
  padding: 0.2rem 0;
  align-items: center;
  justify-content: center;
}
.data-list {
  border-radius: 0.2rem;
  border-top: 1px solid transparent;
  .data-item {
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 0.2rem;
    background: #ffffff;
    border-radius: 0.08rem;
    padding: 0 0.3rem;
    border-bottom: 1px solid transparent;
    .cmpyName {
      font-size: 0.32rem;
      font-weight: bold;
      color: #333333;
      line-height: 1.3;
      padding: 0.2rem 0;
      // height: 1rem;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    .job {
      border-top: 1px solid #ebeef5;
      .row1 {
        display: flex;
        justify-content: space-between;
        margin-top: 0.3rem;
        .post-name {
          width: 1.96rem;
          height: 0.3rem;
          font-size: 0.28rem;
          color: #303133;
          line-height: 0.3rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1.5;
        }
        .post-treatment {
          height: 0.34rem;
          font-size: 0.32rem;
          font-weight: bold;
          color: #ff0000;
          line-height: 0.34rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
          flex: 1;
        }
      }
      .row2 {
        display: flex;
        margin-top: 0.2rem;
        margin-bottom: 0.3rem;
        .tag {
          max-width: 3rem;
          height: 0.32rem;
          line-height: 0.32rem;
          border-radius: 0.08rem;
          // border: 1px solid #999999;
          font-size: 0.24rem;
          color: #909399;
          margin-right: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .read-more {
      font-size: 0.24rem;
      color: #999999;
      margin-bottom: 0.2rem;
      text-align: center;
      p {
        display: inline-block;
        padding: 0 0.3rem;
      }
    }
  }
}

.detail-container {
  .item-list {
    padding: 0.3rem;
    .item {
      padding: 0.3rem 0;
      border-bottom: 1px solid #dfdfdf;
      .item-label {
        font-size: 0.26rem;
        color: #909399;
        line-height: 0.3rem;
        margin-bottom: 0.3rem;
      }
      .item-value {
        font-size: 0.28rem;
        color: #333333;
        line-height: 0.3rem;
      }
    }
    .item:last-of-type {
      border: none;
    }
  }

  .cancel {
    width: 7.5rem;
    height: 1.2rem;
    background: #f7fbff;
    font-size: 0.36rem;
    color: #333333;
    line-height: 0.38rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
